import { toTitleCase } from "./string";
import Big from "big.js";
import { isEmpty } from "lodash";
/**
 * Get Property Address
 */
export function getPropertyAddress(property) {
    let building = !isEmpty(property.building)
        ? `${toTitleCase(property?.building.name.trim())},`
        : "";
    let lotNo = `${toTitleCase(property?.lotNumber?.trim())},`;
    let street = !isEmpty(property.street)
        ? `${toTitleCase(property.street.trim())},`
        : "";
    let area = `${toTitleCase(property?.area?.name.trim())},`;
    let postcode = `${toTitleCase(property?.area?.postalCode.trim())},`;
    let city = `${toTitleCase(property?.city?.name.trim())},`;
    let state = `${toTitleCase(property?.state?.name.trim())},`;
    let country = `${toTitleCase(property?.country?.name.trim())}`;

    let addr = `${building} ${lotNo} ${street} ${area} ${postcode} ${city} ${state} ${country}`;
    return addr;
}

/**
 * Calculate Area
 * @param {Number} width
 * @param {Number} depth
 * @returns
 */
export function calculateArea(width = 0, depth = 0) {
    try {
        width = width === "" ? 0 : width;
        depth = depth === "" ? 0 : depth;

        let w = new Big(width);
        let d = new Big(depth);

        let area = new Big(w.times(d).round(2).toFixed(2));

        return area.toNumber();
    } catch (error) {
        return 0;
    }
}

/**
 * Calculate unit price
 */
export function calculateUnitPrice(price, unit) {
    try {
        let p = new Big(price);
        let u = new Big(unit);

        let uPrice = new Big(p.div(u).round(2).toFixed(2));

        return uPrice;
    } catch (error) {
        return 0;
    }
}

<template>
  <div class="auction-registration-detail">
    <div class="container fluid">
      <!-- ========================= Title Section ========================= -->
      <div class="d-flex align-items-center p-2 mb-2">
        <fd-button class="circle mr-3" @click="goBack">
          <i class="fas fa-chevron-left"></i>
        </fd-button>
        <h1>Auction Registration</h1>
      </div>

      <div class="row">
        <!-- Status & Actions -->
        <div class="card flat mb-3">
          <div class="p-2 sm-p-3">
            <div class="fg-grey-700">
              Auction Registration ID: {{ auctionRegistration.id }}
            </div>
            <div class="fg-grey-500 mb-1">
              Created At:
              {{
                $moment(auctionRegistration.createdAt).format(
                  "DD-MM-YYYY H:mm A"
                )
              }}
            </div>
            <AuctionRegistrationStatus
              class="text-center py-1"
              :style="{ fontSize: '14px', minWidth: '150px' }"
              :status="auctionRegistration.status"
            ></AuctionRegistrationStatus>
            <banner
              v-if="
                auctionRegistration.rejectRemark ||
                auctionRegistration.approveRemark
              "
              class="bg-grey-50 mt-3"
              :dismissable="false"
            >
              <span>Remark</span>
              <p v-if="auctionRegistration.rejectRemark">
                {{ auctionRegistration.rejectRemark }}
              </p>
              <p v-if="auctionRegistration.approveRemark">
                {{ auctionRegistration.approveRemark }}
              </p>
            </banner>
          </div>

          <div
            v-if="auctionRegistration.status.id == STATUS.PENDING"
            class="d-flex justify-content-end p-2 sm-p-3"
          >
            <fd-button class="main medium" @click="openApproveModal">
              <i class="fas fa-check mr-1"></i>Approve
            </fd-button>
            <fd-button class="danger medium ml-2" @click="openRejectModal">
              Reject
            </fd-button>
          </div>
        </div>
        <!-- Agent -->
        <div class="card flat mb-3">
          <agent-info
            class="p-2 sm-p-3"
            :agencyUser="property.agencyUser"
          ></agent-info>
        </div>
      </div>

      <!-- ======================== Content Section ======================== -->

      <!-- Bidder -->
      <div class="card flat p-2 sm-p-3 mb-3">
        <bidder-registration-info
          :bidderInfo="auctionRegistration"
        ></bidder-registration-info>
      </div>
      <!-- Auction -->
      <div class="card flat p-2 sm-p-3 mb-3 row">
        <h3 class="col-12 py-2 mb-3">Auction Details</h3>
        <display-data
          :class="displayFieldClass"
          label="Auction Type"
          :content="propertyAuction.auctionType.name"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Auction Venue"
          :content="propertyAuction.auctionVenue"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Plaintiff/Assignee"
          :content="propertyAuction.assignee"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Auctioneer"
          :content="propertyAuction.auctioneer"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Lawyer"
          :content="propertyAuction.lawyer"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Defendant/Assignor"
          :content="propertyAuction.assignor"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Deposit"
          :content="`${propertyAuction.deposit} %`"
        ></display-data>
      </div>
      <!-- Property -->
      <div class="card flat p-2 sm-p-3 row">
        <h3 class="col-12 py-2 mb-3">Property Info</h3>
        <display-data
          :class="displayFieldClass"
          label="Name"
          :content="property.title"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Reference Code"
          :content="property.referenceCode"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Address"
          :content="getPropertyAddress(property)"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Property Type"
          :content="property.propertyType.name"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Category"
          :content="property.propertyCategory"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Lot Type"
          :content="lotType"
        ></display-data>
        <display-data :class="displayFieldClass" label="Tenure">
          <li v-for="(tenure, index) in property.tenureType" :key="index">
            {{ tenure }}
          </li>
        </display-data>
        <display-data
          :class="displayFieldClass"
          label="Furnishing Type"
          :content="property.furnishingType"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Built-up Area"
          :content="`${property.builtUpArea} ${property.areaUnitType}`"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Land Area"
          :content="`${property.landArea} ${property.areaUnitType}`"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Bedroom"
          :content="convNull(property.numberOfBedroom)"
        ></display-data>
        <display-data
          :class="displayFieldClass"
          label="Bathroom"
          :content="convNull(property.numberOfBathroom)"
        ></display-data>
      </div>
    </div>

    <!-- ======================== Modal ======================== -->
    <modal v-model="isActionModalShown">
      <auction-registration-action-content
        :type="actionModalType"
        @close="closeActionModal"
        @approve="approveRegistration"
        @reject="rejectRegistration"
      ></auction-registration-action-content>
    </modal>
  </div>
</template>

<script>
import { auctionRegistrationStatus as STATUS } from "@/enums";
import { convertNullIfEmpty as convNull } from "@/utils/null-check";
import { auctionRegistration as auctionAPI } from "@/api";
import { getPropertyAddress } from "@/utils/property";

export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    Banner: () => import("@/components/GlobalComponents/Banner"),
    AgentInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/AgentInfo"
      ),
    AuctionRegistrationStatus: () =>
      import("@/components/Auction/AuctionRegistrationStatus"),
    BidderRegistrationInfo: () =>
      import("@/components/Auction/BidderRegistrationInfo"),
    AuctionRegistrationActionContent: () =>
      import("@/components/Auction/AuctionRegistrationActionContent")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      STATUS,
      getPropertyAddress,
      convNull,

      displayFieldClass: "col-12 sm-col-6",

      isActionModalShown: false,
      actionModalType: "",

      auctionRegistration: {
        status: { id: "" },
        bidderType: { name: "" },
        icFrontPhotos1: [],
        icBackPhotos1: []
      },
      propertyAuction: {
        assignee: "",
        assignor: "",
        auctionDate: "",
        auctionTime: "",
        auctionType: { name: "" },
        auctionVenue: "",
        auctioneer: "",
        deposit: "",
        id: "",
        lawyer: ""
      },
      property: {
        agencyUser: {},

        country: { name: "" },
        city: { name: "" },
        state: { name: "" },
        area: { name: "", postalCode: "" },
        street: "",
        building: { name: "" },

        title: "",
        areaUnitType: "",
        builtUpArea: "",
        landArea: "",
        furnishingType: "",
        lotNumber: "",
        lotType: {
          name: ""
        },
        mainPhoto: [{ path: "" }],
        numberOfBathroom: "",
        numberOfBedroom: "",
        propertyAuction: "",
        propertyCategory: "",
        propertyType: "",
        referenceCode: "",
        salePrice: "",
        salePricePerUnit: "",
        tenureType: ""
      }
    };
  },
  computed: {
    lotType() {
      return this.property.lotType?.name;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAuctionRegistration();
    },
    goBack() {
      this.$router.push({
        name: "AuctionRegistrationList"
      });
    },
    openApproveModal() {
      this.actionModalType = "Approve";
      this.isActionModalShown = true;
    },
    openRejectModal() {
      this.actionModalType = "Reject";
      this.isActionModalShown = true;
    },
    closeActionModal() {
      this.isActionModalShown = false;
    },
    async getAuctionRegistration() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await auctionAPI.getAuctionRegistration(
          this.$route.params.id
        );
        this.auctionRegistration = this._.cloneDeep(data);
        this.propertyAuction = this._.cloneDeep(data.property.propertyAuction);
        this.property = this._.cloneDeep(data.property);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text:
            "Failed to fetch auction registration detail. Please try again later."
        });
        this.$store.commit("setIsLoading", false);
      }
    },
    async approveRegistration(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await auctionAPI.approveAuctionRegistration(
          this.$route.params.id,
          payload
        );
        this.closeActionModal();
        this.$notify({
          group: "alert",
          type: "success",
          title: "Approved",
          text: "Auction registration approved."
        });

        await this.getAuctionRegistration();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Approve Failed",
          text: "Failed to approve auction registration."
        });
      }
    },
    async rejectRegistration(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await auctionAPI.rejectAuctionRegistration(
          this.$route.params.id,
          payload
        );
        this.closeActionModal();
        this.$notify({
          group: "alert",
          type: "success",
          title: "Rejected",
          text: "Auction registration rejected."
        });
        await this.getAuctionRegistration();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Reject Failed",
          text: "Failed to reject auction registration."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
